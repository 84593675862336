
.bold {
    font-weight: bold !important;
}

.menu a {
    color: #FFF !important;
}


.sidemenu {
    margin-top: 50px !important;
}

.ant-table-thead tr th {
    background: rgb(64, 64, 65) !important;
    color: #FFF !important;
    font-weight: bold !important;
}

.ant-typography {
    text-transform: uppercase !important;
}


.ant-checkbox {
    margin-left: 10px !important;
}

.ant-menu {
    border: none !important;

}

.form-button,
.form-button:hover {
    font-variant: small-caps;
    font-weight: bolder;
    width: 40%;
    background: #8c52ff;
    font-size: 15px;
    margin-top: -10px;
}

.login {
    width: 470px;
    background: #FFF;
    border-radius: 10px;
    padding: 40px;
    font-size: 20px;
}

.site-form-item-icon {
    padding: 15px;
}

.login-form-button,
.login-form-button:hover {
    font-variant: small-caps;
    font-weight: bolder;
    width: 40%;
    background: #8c52ff;
    font-size: 15px;
    margin-top: -10px;
}

/*Register*/
.pageCenter {
    width: 500px;
    background: #FFF;
    border-radius: 10px;
    padding: 40px;
    font-size: 20px;
}

.register-form-button,
.register-form-button:hover {
    font-variant: small-caps;
    font-weight: bolder;
    width: 40%;
    background: #8c52ff;
    font-size: 15px;
}

/*Ticket*/
.dashboard {
    width: 15%;
    border: 2pt solid rgb(197, 194, 194);
    margin: 20px;
    padding: 30px;
    font-size: 15pt;
}

.db-count {
    width: '50px';
    max-width: '50px';
    margin-left: '172px';
    margin-top: '-95px';
    text-align: 'center';
    border: '2pt solid rgb(197, 194, 194)';
    border-right: 'none';
    border-top: 'none';
    z-index: '10000'
}



.buttonControls
{
    margin-bottom: 10px;
}