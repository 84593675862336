.App {
  text-align: center;
}
.body
{
  background: #FFF !important;
}
.bannerLogo {
  height: 20min;
  width: 20min;
  display: inline;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-content
{
    overflow: scroll;
}

.layout {
  width: "100%" !important;
  height: "100vh" !important;
  position:"fixed" !important;
}


.imgSize{
  width: 25%;
}
.colSize
{
  padding: 10px 5px 0px 5px;
  font-size: 13px;
  word-wrap: break-word;
}
.App{
  margin: 0px 20px;
}
.headTitle
{
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 20px;
  color:rgb(59, 59, 59);
}

.header
{
  position: 'fixed' !important;
  z-index: 1 !important;
  width: 100%;
  
}

.contentLayout
{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.sideLayout
{
  padding:60px 0px 0px 0px !important;
}

.appCard
{
  display: inline-block;
  width: 100px;
  height: 100px;
  padding: 5px;
  margin: 10px;
  text-align: center;
}

.appIcon
{
  width: 100px;
  height: 100px;
}

.utilsIcon
{
  width: 70px;
  height: 70px;
}

.appName{
  padding-top: 20px;
  color:#000;
  font-weight: bold;
}
.appCategory
{
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 50px;
  text-align: center;
  max-width: 1024px;
}

.pageCenter
{
  margin-left: auto;
  margin-right: auto;
}

.ck-rounded-corners
{
 border: 1px solid gray !important; 
}

.pagination-control
{
  gap: 0 !important;
}



.context-menu {
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}


.context-menu li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  border-bottom: 1px solid gray;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}
.context-menu li:hover {
  background-color: #88d9ff;
}
.popup li > i {
  margin-right: 8px;
}


.thCard
{
  box-sizing: border-box;
  margin: 0;
  border: 1px solid rgb(97, 97, 97);
  padding: 15px 20px 10px 20px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.cm-scroller
{
  border: 1px solid rgb(199, 199, 199);
}